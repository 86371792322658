import { useCallback, useMemo } from 'react'

import { v4 as uuid } from 'uuid'

import { FilterItem, FilterWrapper } from '../../atoms'

type DataTestProps = {
  filterItem: { name: string }
}

type FilterRelationsProps = {
  urlKeyHasRelations: string
  urlKeyWithoutRelations: string
  disabled?: boolean
  onChange: (value: Record<string, boolean | null>) => void
  hasRelations?: boolean | null
  withoutRelations?: boolean | null
  dataTestIdFor?: Partial<DataTestProps>
}

// Константы для названий фильтров
const FILTER_LABELS = {
  WITH_RELATIONS: 'Есть связи',
  WITHOUT_RELATIONS: 'Нет связей',
} as const

export const FilterRelations = ({
  urlKeyHasRelations,
  urlKeyWithoutRelations,
  disabled,
  onChange,
  hasRelations,
  withoutRelations,
  dataTestIdFor,
}: FilterRelationsProps) => {
  // Обработчик для фильтра "Есть связи"
  const handleWithRelationsChange = useCallback(() => {
    // Если выбран противоположный фильтр - сбрасываем его
    if (withoutRelations) {
      onChange({ [urlKeyWithoutRelations]: null })
      return
    }

    // Если текущий фильтр активен - сбрасываем его
    if (!withoutRelations && hasRelations) {
      onChange({ [urlKeyHasRelations]: null })
    }

    // Включаем противоположный фильтр
    onChange({ [urlKeyWithoutRelations]: true })
  }, [
    onChange,
    urlKeyHasRelations,
    urlKeyWithoutRelations,
    hasRelations,
    withoutRelations,
  ])

  // Обработчик для фильтра "Нет связей"
  const handleWithoutRelationsChange = useCallback(() => {
    // Если выбран противоположный фильтр - сбрасываем его
    if (hasRelations) {
      onChange({ [urlKeyHasRelations]: null })
      return
    }

    // Если текущий фильтр активен - сбрасываем его
    if (!hasRelations && withoutRelations) {
      onChange({ [urlKeyWithoutRelations]: null })
    }

    // Включаем противоположный фильтр
    onChange({ [urlKeyHasRelations]: true })
  }, [
    onChange,
    urlKeyHasRelations,
    urlKeyWithoutRelations,
    hasRelations,
    withoutRelations,
  ])

  const items = useMemo(
    () => [
      {
        uniqId: uuid(),
        selected: !withoutRelations,
        name: FILTER_LABELS.WITH_RELATIONS,
        disabled,
        onChange: handleWithRelationsChange,
      },
      {
        uniqId: uuid(),
        selected: !hasRelations,
        name: FILTER_LABELS.WITHOUT_RELATIONS,
        disabled,
        onChange: handleWithoutRelationsChange,
      },
    ],
    [
      disabled,
      hasRelations,
      withoutRelations,
      handleWithRelationsChange,
      handleWithoutRelationsChange,
    ],
  )

  const filterName = dataTestIdFor?.filterItem?.name

  return (
    <FilterWrapper>
      {items.map(item => (
        <FilterItem
          key={item.uniqId}
          item={item}
          onChange={item.onChange}
          dataTestId={filterName && `filter${filterName}_${item.name}`}
        />
      ))}
    </FilterWrapper>
  )
}
