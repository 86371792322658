import { userInfo$ } from '@gmini/common'
import {
  FilterAssigneeMultiple,
  FilterAttribute,
  FilterDate,
  FilterPanel,
  FilterRelations,
  FilterShow,
  FilterStatus,
  FilterUser,
} from '@gmini/components'
import { countNonMatchingItems } from '@gmini/utils'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

import { equals, isEmpty, isNil } from 'ramda'

import {
  ASSIGNEES_IL,
  ATTRIBUTES_IL,
  AUTHOR_IDS_IL,
  CREATED_DATE_CODE_IL,
  CREATED_DATE_RANGE_IL,
  DEADLINE_CODE_IL,
  DEADLINE_RANGE_IL,
  DateFilterItemsCode,
  IssueListFiltersType,
  IssueQueryType,
  SHOW_IL,
  STATUSES_IL,
  UPDATED_DATE_CODE_IL,
  UPDATED_DATE_RANGE_IL,
  WITHOUT_RELATIONS_IL,
  HAS_RELATIONS_IL,
  filtersTitle,
} from '@gmini/helpers'

import { FilterShowItemType } from '@gmini/components/src/molecules/FilterShow/FilterShow.types'

import { PROJECT_IS_NOT_SELECTED_ERROR } from '../../../constants'

import { assigneeGroupList$ } from '../../assigneeGroupList'
import { fetchCompanyListPending$ } from '../../company.store'

import {
  fetchStatusListPending$,
  issueStatusList$,
} from '../../issueStatus.store'
import { fetchRoleListPending$ } from '../../role.store'
import {
  fetchProjectUserListPending$,
  projectUserList$,
} from '../../user.store'

import { filterService } from '../../issueFilter.store'

import { attributesService } from '../../attribute.store'

import { seoEventManager } from '../../../config'

import { fetchIssueListPending$ } from '../../issue.store'

import { usedAttributes$ } from '../../usedAttributes'

import { getIssueListFilterDeadlineOptions } from './issueListDeadlineOptions'

import {
  allowedFilters$,
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} from './model'

type IssueListFilterPanelProps = {
  onChange: (filters: IssueListFiltersType) => void
  projectUrn: string | null
}

export const IssueListFilterPanel = ({
  onChange,
  projectUrn,
}: IssueListFilterPanelProps) => {
  const allowedFilters = useStore(allowedFilters$)
  const userList = useStore(projectUserList$)
  const fetchIssueListPending = useStore(fetchIssueListPending$)
  const fetchUserListPending = useStore(fetchProjectUserListPending$)
  const fetchCompanyListPending = useStore(fetchCompanyListPending$)
  const fetchRoleListPending = useStore(fetchRoleListPending$)
  const fetchAllowFiltersPending = useStore(fetchAllowedFiltersPending$)
  const issueStatusList = useStore(issueStatusList$)
  const fetchStatusListPending = useStore(fetchStatusListPending$)
  const userInfo = useStore(userInfo$)
  const assigneeGroupList = useStore(assigneeGroupList$)
  const attributes = useStore(usedAttributes$)
  const {
    appliedFilters: { appliedFilters$, useUpdateFilter },
  } = filterService

  const appliedFilters = useStore(appliedFilters$)
  const { update } = useUpdateFilter()

  useEffect(() => {
    if (!projectUrn) {
      return
    }

    fetchAllowedFilters({
      projectUrn,
      showNotRelevant: true,
    })
  }, [projectUrn])

  const onChangeFilterHandler = () => {
    const filters = appliedFilters$.getState()
    onChange(filters)

    if (!projectUrn) {
      throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
    }

    fetchAllowedFilters({
      projectUrn,
      showNotRelevant: true,
    }) // Чтобы хэндлить изменения замечаний другими пользователями
  }
  const onChangeShowHandler = (filter: IssueQueryType) => {
    update(filter)

    onChangeFilterHandler()
  }
  const onChangeAttributeHandler = (filter: IssueQueryType) => {
    update(filter)

    onChangeFilterHandler()
  }
  const onChangeStatusHandler = (filter: IssueQueryType) => {
    update(filter)

    onChangeFilterHandler()

    const filtersCount = countNonMatchingItems({
      dictionary: appliedFilters,
      predicates: [isEmpty, isNil, key => equals(key, DateFilterItemsCode.ALL)],
    })

    seoEventManager.push({
      event: 'Gstation_Issues_FilterMenu_SetFilter',
      payload: {
        filterName: filtersTitle.status,
        activeFiltersCount: filtersCount,
      },
    })
  }

  const onChangeRelationsHandler = (filter: IssueQueryType) => {
    update(filter)
    onChangeFilterHandler()

    const filtersCount = countNonMatchingItems({
      dictionary: appliedFilters,
      predicates: [isEmpty, isNil, key => equals(key, DateFilterItemsCode.ALL)],
    })

    seoEventManager.push({
      event: 'Gstation_Issues_FilterMenu_SetFilter',
      payload: {
        filterName: filtersTitle.relations,
        activeFiltersCount: filtersCount,
      },
    })
  }

  const onChangeUpdatedAtHandler = (filter: IssueQueryType) => {
    update(filter)
    onChangeFilterHandler()

    const filtersCount = countNonMatchingItems({
      dictionary: appliedFilters,
      predicates: [isEmpty, isNil, key => equals(key, DateFilterItemsCode.ALL)],
    })

    seoEventManager.push({
      event: 'Gstation_Issues_FilterMenu_SetFilter',
      payload: {
        filterName: filtersTitle.updatedAt,
        activeFiltersCount: filtersCount,
      },
    })
  }

  const onChangeCreatedAtHandler = (filter: IssueQueryType) => {
    update(filter)
    onChangeFilterHandler()

    const filtersCount = countNonMatchingItems({
      dictionary: appliedFilters,
      predicates: [isEmpty, isNil, key => equals(key, DateFilterItemsCode.ALL)],
    })

    seoEventManager.push({
      event: 'Gstation_Issues_FilterMenu_SetFilter',
      payload: {
        filterName: filtersTitle.createAt,
        activeFiltersCount: filtersCount,
      },
    })
  }

  const onChangeDeadlineHandler = (filter: IssueQueryType) => {
    update(filter)
    onChangeFilterHandler()

    const filtersCount = countNonMatchingItems({
      dictionary: appliedFilters,
      predicates: [isEmpty, isNil, key => equals(key, DateFilterItemsCode.ALL)],
    })

    seoEventManager.push({
      event: 'Gstation_Issues_FilterMenu_SetFilter',
      payload: {
        filterName: filtersTitle.deadline,
        activeFiltersCount: filtersCount,
      },
    })
  }

  const onChangeAuthorHandler = (filter: IssueQueryType) => {
    update(filter)
    onChangeFilterHandler()

    const filtersCount = countNonMatchingItems({
      dictionary: appliedFilters,
      predicates: [isEmpty, isNil, key => equals(key, DateFilterItemsCode.ALL)],
    })

    seoEventManager.push({
      event: 'Gstation_Issues_FilterMenu_SetFilter',
      payload: {
        filterName: filtersTitle.author,
        activeFiltersCount: filtersCount,
      },
    })
  }

  const onChangeAssigneeHandler = (filter: IssueQueryType) => {
    update(filter)
    onChangeFilterHandler()

    const filtersCount = countNonMatchingItems({
      dictionary: appliedFilters,
      predicates: [isEmpty, isNil, key => equals(key, DateFilterItemsCode.ALL)],
    })

    seoEventManager.push({
      event: 'Gstation_Issues_FilterMenu_SetFilter',
      payload: {
        filterName: filtersTitle.assignee,
        activeFiltersCount: filtersCount,
      },
    })
  }

  const fetchAttributePendingMap = useStore(
    attributesService.fetchAttributePendingMap$,
  )
  const attributeValueById = useStore(attributesService.attributeValueById$)
  const attributeItems = attributes
    .filter(attr =>
      allowedFilters?.attributeValueIds.some(id => {
        const item = attributeValueById[id]
        return item?.attribute?.id === attr.id
      }),
    )
    .map(({ id, name }) => ({
      title: name,
      component: (
        <FilterAttribute
          dataTestIdFor={{ filterItem: { name: 'AttibuteValueId' } }}
          allowedItems={allowedFilters?.attributeValueIds || []}
          urlKey={ATTRIBUTES_IL}
          attributeValueById$={attributesService.attributeValueById$}
          attributeId={id}
          loading={false}
          disabled={
            (!allowedFilters && fetchAttributePendingMap[id]) ||
            fetchIssueListPending
          }
          countSkeletonItems={6}
          onChange={onChangeAttributeHandler}
          value={appliedFilters.attributeValueIds || []}
        />
      ),
    }))

  return (
    <FilterPanel
      items={[
        {
          title: filtersTitle.show,
          component: (
            <FilterShow
              dataTestIdFor={{ filterItem: { name: 'Show' } }}
              typeList={[
                { name: 'Все', type: FilterShowItemType.ALL },
                {
                  name: 'Назначенные на меня',
                  type: FilterShowItemType.FOR_ME,
                },
                { name: 'Отслеживаемые', type: FilterShowItemType.SUBSCRIBED },
              ]}
              urlKey={SHOW_IL}
              disabled={
                (!allowedFilters && fetchAllowFiltersPending) ||
                fetchIssueListPending
              }
              countSkeletonItems={2}
              onChange={onChangeShowHandler}
              value={appliedFilters.show}
            />
          ),
        },
        {
          title: filtersTitle.status,
          component: (
            <FilterStatus
              dataTestIdFor={{ filterItem: { name: 'StatusBy' } }}
              allowedItems={allowedFilters?.statuses || []}
              statusList={issueStatusList}
              loading={fetchStatusListPending}
              disabled={
                (!allowedFilters && fetchAllowFiltersPending) ||
                fetchIssueListPending
              }
              urlKey={STATUSES_IL}
              countSkeletonItems={6}
              onChange={onChangeStatusHandler}
              value={appliedFilters?.statuses || []}
            />
          ),
        },
        {
          title: filtersTitle.updatedAt,
          component: (
            <FilterDate
              dataTestIdFor={{ filterItem: { name: 'ChangingDate' } }}
              allowedRange={allowedFilters?.updatedDateRange || null}
              urlKeyCode={UPDATED_DATE_CODE_IL}
              urlKeyValue={UPDATED_DATE_RANGE_IL}
              disabled={
                (!allowedFilters && fetchAllowFiltersPending) ||
                fetchIssueListPending
              }
              onChange={onChangeUpdatedAtHandler}
              value={appliedFilters?.updatedDateCode}
            />
          ),
        },
        {
          title: filtersTitle.createAt,
          component: (
            <FilterDate
              dataTestIdFor={{ filterItem: { name: 'CreationDate' } }}
              allowedRange={allowedFilters?.createdDateRange || null}
              urlKeyCode={CREATED_DATE_CODE_IL}
              urlKeyValue={CREATED_DATE_RANGE_IL}
              onChange={onChangeCreatedAtHandler}
              value={appliedFilters?.createdDateCode}
              disabled={
                (!allowedFilters && fetchAllowFiltersPending) ||
                fetchIssueListPending
              }
            />
          ),
        },
        {
          title: filtersTitle.deadline,
          component: (
            <FilterDate
              dataTestIdFor={{ filterItem: { name: 'Deadline' } }}
              allowedRange={allowedFilters?.createdDateRange || null}
              urlKeyCode={DEADLINE_CODE_IL}
              urlKeyValue={DEADLINE_RANGE_IL}
              onChange={onChangeDeadlineHandler}
              value={appliedFilters?.deadlineCode}
              disabled={
                (!allowedFilters && fetchAllowFiltersPending) ||
                fetchIssueListPending
              }
              options={getIssueListFilterDeadlineOptions(new Date())}
            />
          ),
        },
        {
          title: filtersTitle.relations,
          component: (
            <FilterRelations
              urlKeyHasRelations={HAS_RELATIONS_IL}
              urlKeyWithoutRelations={WITHOUT_RELATIONS_IL}
              onChange={onChangeRelationsHandler}
              hasRelations={appliedFilters.hasRelations}
              withoutRelations={appliedFilters.withoutRelations}
            />
          ),
        },
        {
          title: filtersTitle.author,
          component: (
            <FilterUser
              userList={userList}
              urlKey={AUTHOR_IDS_IL}
              allowedItems={allowedFilters?.authorIds || []}
              placeholder='Выбрать автора'
              disabled={
                fetchUserListPending ||
                (!allowedFilters && fetchAllowFiltersPending) ||
                fetchIssueListPending
              }
              userInfo={userInfo}
              labelFirstEl='Я автор'
              onChange={onChangeAuthorHandler}
              value={appliedFilters.authorIds || []}
            />
          ),
        },
        {
          title: filtersTitle.assignee,
          component: (
            <FilterAssigneeMultiple
              assigneeGroupList={assigneeGroupList}
              urlKey={ASSIGNEES_IL}
              allowedItems={allowedFilters?.assignees || []}
              placeholder='Выбрать на кого на...'
              disabled={
                fetchUserListPending ||
                fetchCompanyListPending ||
                fetchRoleListPending ||
                (!allowedFilters && fetchAllowFiltersPending) ||
                fetchIssueListPending
              }
              userInfo={userInfo}
              labelFirstEl='Назначено на меня'
              onChange={onChangeAssigneeHandler}
              value={appliedFilters.assignees || []}
            />
          ),
        },
        ...attributeItems,
      ]}
    />
  )
}
