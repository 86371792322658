import { Button, IconButton, Save, TextField, Tooltip } from '@gmini/ui-kit'
import { countNonMatchingItems } from '@gmini/utils'

import {
  Card,
  CardActions,
  CardContent,
  Popover,
  Typography,
} from '@mui/material'
import { MouseEvent, useState, useCallback } from 'react'
import { useStore } from 'effector-react'

import { DateFilterItemsCode } from '@gmini/helpers'

import { equals, isEmpty, isNil } from 'ramda'

import { SeoEventManager } from '@gmini/common'

import { filterService } from '../../issueFilter.store'

import { InputWrapper, Label, LabelText, Success } from './SaveFilter.styled'

type SaveFilterProps<T> = {
  appliedFilterElements: T
  renderAppliedFilterElements: (props: T) => JSX.Element
  seoEventManager?: SeoEventManager
  onSubmit?: (tittle: string) => void
  onCancel?: () => void
  disableSubmitButton?: boolean
  disable?: boolean
  visible?: boolean
  isExist?: boolean
}

export const SaveFilter = <T,>({
  appliedFilterElements,
  renderAppliedFilterElements,
  onSubmit,
  onCancel,
  disableSubmitButton,
  visible = true,
  disable,
  isExist,
  seoEventManager,
}: SaveFilterProps<T>) => {
  const {
    appliedFilters: { appliedFilters$ },
  } = filterService

  const appliedFilters = useStore(appliedFilters$)
  const [openPopper, setOpenPopper] = useState<HTMLButtonElement | null>(null)
  const [filterName, setFilterName] = useState<string>('')
  const closePopper = () => {
    setFilterName('')
    setOpenPopper(null)
  }

  const seoEventHandler = useCallback(() => {
    const appliedNames = Object.entries(appliedFilters).reduce(
      (acc, [key, val]) =>
        typeof val === 'boolean' ||
        (typeof val === 'string' && val.length) ||
        (!Array.isArray(val) && val)
          ? `${acc}${acc ? ', ' : ''}${key}`
          : acc,
      '',
    )
    const filtersCount = countNonMatchingItems({
      dictionary: appliedFilters,
      predicates: [isEmpty, isNil, key => equals(key, DateFilterItemsCode.ALL)],
    })

    seoEventManager?.push({
      event: 'Gstation_Issues_Filters_Save',
      payload: {
        filterName: appliedNames,
        activeFiltersCount: filtersCount,
      },
    })
  }, [appliedFilters, seoEventManager])

  if (!visible) {
    return null
  }
  return (
    <>
      <Tooltip
        placement='top'
        title={isExist ? 'Фильтр уже сохранен' : 'Сохранить в мои фильтры'}
        styleContent={{ marginLeft: 'auto' }}
      >
        <IconButton
          onClick={(event: MouseEvent<HTMLButtonElement>) =>
            setOpenPopper(event.currentTarget)
          }
          disabled={disable}
        >
          {isExist ? <Success /> : <Save color='rgba(53, 59, 96, 0.5)' />}
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(openPopper)}
        anchorEl={openPopper}
        onClose={() => setOpenPopper(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card sx={{ width: 348 }}>
          <CardContent sx={{ padding: '24px 24px 0' }}>
            <Typography
              sx={{
                fontSize: '20px',
                lineHeight: '21px',
                marginBottom: '24px',
              }}
            >
              Сохранить фильтр
            </Typography>
            <InputWrapper>
              <Label>
                <LabelText>Название фильтра</LabelText>
                <TextField
                  value={filterName}
                  onChange={setFilterName}
                  autofocus
                />
              </Label>
            </InputWrapper>
            {renderAppliedFilterElements(appliedFilterElements)}
          </CardContent>

          <CardActions
            sx={{ justifyContent: 'flex-end', padding: '16px 24px 16px' }}
          >
            <Button
              color='secondary'
              onClick={() => {
                closePopper()
                onCancel?.()
              }}
            >
              Отменить
            </Button>
            <Button
              color='info'
              disabled={disableSubmitButton || !filterName}
              onClick={() => {
                closePopper()
                onSubmit?.(filterName)
                seoEventHandler()
              }}
            >
              Сохранить
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  )
}
